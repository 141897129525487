import React from "react"
import { IMG } from "../const"

export const WEB_DEVELOPMENT_SERVICES = [
  {
    id: 1,
    title: "Startups",
    text: "that lack a tech team in the early stages.",
  },
  {
    id: 2,
    title: "Founders",
    text:
      "of small and medium-sized businesses that want to gain a market advantage.",
  },
  {
    id: 3,
    title: "CTO",
    text:
      "who needs to create fast & secure web apps using modern technologies.",
  },
  {
    id: 4,
    title: "Consulting",
    text: "that meets client needs at a competitive level.",
  },
]

export const TITLES = {
  cost_saving_approach: {
    title: (
      <>
        get a&nbsp;<strong>custom web development</strong> service that empowers
        business
      </>
    ),
    strongText: "custom web dev for your specific needs",
    uppercase: (
      <>
        cost-saving <br />
        approach
      </>
    ),
    withIndent: true,
  },
  range_services: {
    title: (
      <>
        our <strong>web development</strong> services
      </>
    ),
    strongText: "an all-in-one web development company",
    uppercase: (
      <>
        a&nbsp;wide range of <br />
        services
      </>
    ),
    withIndent: false,
  },
  tech_stack: {
    title: (
      <>
        our <strong>tech stack</strong>
      </>
    ),
    strongText: "web development tech stack",
    uppercase: (
      <>
        modern web <br />
        technologies
      </>
    ),
    withIndent: false,
  },
  advantages: {
    title: (
      <>
        why you should <strong>choose</strong> lasoft:
      </>
    ),
    strongText:
      "cooperating with us will allow you to land a high-quality web app within your budget",
    uppercase: "advantages",
    withIndent: true,
  },
  case_studies: {
    title: (
      <>
        case studies we're <strong>proud of</strong>
      </>
    ),
    strongText:
      "we use a structured and tested approach to provide beautiful digital services for our partners",
    uppercase: (
      <>
        cases from
        <br />
        10+ industries
      </>
    ),
    withIndent: true,
  },
  colored: {
    title: (
      <>
        interested in <br /> web development <br /> service?
      </>
    ),
    strongText:
      "request a consultation today so that we can help build your web product",
    uppercase: "Project estimate",
    link: "#contact-form",
    linkClassName: "customweb-estimate",
    imageDesktop: `${IMG.WEB_DEVELOPMENT}/scheme-white.svg`,
    imageDesktopWidth: 818,
    imageDesktopHeight: 92,
    withIndent: false,
  },
}

export const SERVICES = {
  text: "",
  items: [
    {
      id: 1,
      title: "Custom web development",
      text: (
        <p>
          Do you need a&nbsp;unique solution to meet your special business
          requirements? We have extensive experience creating custom web
          products, from scratch to ready‑to‑use solutions that perform well and
          have an intuitive user experience.
        </p>
      ),
      link: "",
      image: "web-dev",
    },
    {
      id: 2,
      title: "Web application development",
      text: (
        <p>
          Looking for efficient management solutions for business activities? We
          are ready to develop a&nbsp;user‑friendly web application to achieve
          your goals. We can integrate them seamlessly with larger web projects.
          Web applications allow businesses to streamline operations, increase
          efficiency, and reduce costs.
        </p>
      ),
      link: "",
      image: "web-application",
    },
    {
      id: 3,
      title: "B2B portal development",
      text: (
        <p>
          Do you need a&nbsp;powerful web portal for your business to generate
          relevant content and collect user data quickly? We develop excellent
          interactive web portals that automatically generate up‑to‑date
          information for target audiences.
        </p>
      ),
      link: "",
      image: "portal-dev",
    },
    {
      id: 4,
      title: "E‑commerce development",
      text: (
        <p>
          Are you planning to sell goods online, or are you a&nbsp;national
          leader in selling goods online? We have experience with both. Our
          e‑commerce web development services include many features that bring
          your business online, including reliable and secure payment methods,
          customizable checkout flows, and an integrated shopping cart system.
        </p>
      ),
      link: "",
      image: "e-commerce-dev",
    },
  ],
}

export const ADVANTAGES = [
  {
    id: 1,
    title: "Cost Savings",
    text:
      "We build web projects with our customizable low-code solution, saving you up to 35% of your time and budget to launch your unique web solution. Moreover, our team can easily develop new features and customize the solution after the initial launch.",
  },
  {
    id: 2,
    title: "Transparent communication",
    text:
      "We know how important transparent communication with customers is throughout the entire service process. That's why our delivery managers constantly communicate with clients about all important aspects of web development.",
  },
  {
    id: 3,
    title: "On Time Delivery",
    text:
      "Within the framework of well-established processes, we deliver finished digital solutions and individual intermediate results on time.",
  },
  {
    id: 4,
    title: "Experience",
    text:
      "After nearly 10 years of experience in delivering web products across multiple industries in more than 10 international markets, our professional teams have excellent hands-on experience in discovery, design, development, and launch.",
  },
  {
    id: 5,
    title: "One-stop shop",
    text:
      "We are a company that covers the full-cycle of website development, from market research and product and UX/UI design to web programming, testing, and support of web products. We do not work with contractors, as we have all the required expertise in our professional teams.",
  },
]
