import React from "react"
import loadable from "@loadable/component"
import cx from "classnames"

import { IMG } from "../../const"
import * as styles from "./cost-saving.module.scss"

const TitleBlock = loadable(() => import("../title-block"))

const CoastSaving = ({ title }) => (
  <section className={cx(styles.costSaving, "section")}>
    <TitleBlock options={title} />
    <div className={styles.text}>
      <div>
        <p>
          <strong>
            We have extensive expertise in delivering complex web projects and
            ensuring our clients receive beautiful, custom web products after
            working with us.
          </strong>
        </p>
        <p>
          Some web products may seem similar from the outside, but our almost 10
          years of experience in web development allow us to know what factors
          are true winners.
        </p>
      </div>
      <div>
        <p>
          We get to know your business model and conduct market research to
          customize an ideal solution. Only after this step do we start building
          your web product. This approach allows us to create a unique solution
          explicitly tailored to our client's needs and saves 35% of the
          client's budget.
        </p>
        <p>
          Put your trust in us so that you can concentrate on developing your
          business.
        </p>
      </div>
    </div>
    <div className={styles.img}>
      <img
        src={`${IMG.WEB_DEVELOPMENT}/scheme-black.svg`}
        alt="Scheme"
        width="1208"
        height="111"
        loading="lazy"
      />
    </div>
  </section>
)

export default CoastSaving
