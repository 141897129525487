// extracted by mini-css-extract-plugin
export var accordionBlock = "web-development-module--accordionBlock--819ef";
export var accordionWrapper = "web-development-module--accordionWrapper--88875";
export var billetShadow = "web-development-module--billet-shadow--abc9c";
export var colorScheme__background__grayPale = "web-development-module--colorScheme__background__gray-pale--90fb4";
export var desktopPosition = "web-development-module--desktopPosition--46293";
export var flexBlock = "web-development-module--flexBlock--a2207";
export var headline = "web-development-module--headline--5cd8d";
export var headline_grid = "web-development-module--headline_grid--cad52";
export var icomoon = "web-development-module--icomoon--65495";
export var lowercase = "web-development-module--lowercase--15d8d";
export var strongText = "web-development-module--strongText--662fb";
export var strongTextBlock = "web-development-module--strongTextBlock--d2c06";
export var techStack = "web-development-module--techStack--06a66";
export var techStack__wrapper = "web-development-module--techStack__wrapper--1540f";
export var transition = "web-development-module--transition--c6748";
export var transitionBackground = "web-development-module--transition-background--53444";
export var transitionReverse = "web-development-module--transition-reverse--bf461";
export var uppercase = "web-development-module--uppercase--4fba1";
export var webDevelopmentBlock = "web-development-module--webDevelopmentBlock--47485";